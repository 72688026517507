import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "text"
};
const _hoisted_2 = {
  class: "text"
};
const _hoisted_3 = {
  class: "van-hairline--top"
};
const _hoisted_4 = {
  class: "text"
};
const _hoisted_5 = {
  class: "text"
};
const _hoisted_6 = {
  class: "van-hairline--top"
};
const _hoisted_7 = {
  class: "center"
};
import Footer from '@/view/box/components/footer.vue';
import { onMounted, reactive, ref } from 'vue';
import axios from 'axios';
import { authorization } from '../../../public/static/js/headers';
import { closeDialog, showConfirmDialog, showFailToast, showImagePreview, showNotify, showSuccessToast } from 'vant';
export default {
  __name: 'myPaper',
  setup(__props) {
    const loading = ref(false);
    const finished = ref(false);
    const active = ref(2);
    const kefu = ref(false);
    onMounted(async () => {
      loading.value = true;
      await getMyPaper(data);
      loading.value = false;
      finished.value = true;
    });
    const onClickTab = async ({
      name
    }) => {
      loading.value = true;
      data.type = Number(name);
      await getMyPaper(data);
      loading.value = false;
      finished.value = true;
    };
    const showImage = (index, paths) => {
      showImagePreview({
        images: paths.map(item => item.path),
        startPosition: index,
        closeable: true
      });
    };
    const data = reactive({
      type: 2
    });
    let myExtractPaper = reactive([{
      id: '',
      paperId: '',
      userId: '',
      number: '',
      numberType: '',
      declaration: '',
      paperLife: 0,
      paperLifeShow: 0,
      squareShow: 1,
      province: '',
      city: '',
      lon: '',
      lat: '',
      sex: 0,
      paperStatus: 0,
      pictureUrlList: [{
        pictureId: '',
        path: ''
      }]
    }]);
    let myPaper = reactive([{
      id: '',
      paperId: '',
      userId: '',
      number: '',
      numberType: '',
      declaration: '',
      paperLife: 0,
      paperLifeShow: 0,
      squareShow: 1,
      province: '',
      city: '',
      lon: '',
      lat: '',
      sex: 0,
      paperStatus: 0,
      pictureUrlList: [{
        pictureId: '',
        path: ''
      }]
    }]);
    const getMyPaper = async data => {
      await axios.post('/api/appPaper/getMyPaper', data, authorization()).then(res => {
        const paper = res.data.data;
        myPaper = [];
        myExtractPaper = [];
        if (data.type === 1) {
          for (let i = 0; i < paper.length; i++) {
            myExtractPaper.push(paper[i]);
          }
        } else {
          for (let i = 0; i < paper.length; i++) {
            myPaper.push(paper[i]);
          }
        }
      }).catch(error => {
        showFailToast('我的纸条加载失败!');
      });
    };
    const destructionPaper = paperId => {
      showConfirmDialog({
        title: '纸条销毁提示',
        message: '纸条销毁后将无法找回，是否继续销毁。'
      }).then(() => {
        closeDialog();
        axios.post('/api/appPaper/doExamine', {
          paperId: paperId,
          paperStatus: 3
        }, authorization()).then(async res => {
          showSuccessToast('纸条销毁成功');
          location.reload();
        }).catch(error => {
          showFailToast('纸条销毁失败');
        });
      }).catch(() => {
        closeDialog();
      });
    };
    return (_ctx, _cache) => {
      const _component_van_col = _resolveComponent("van-col");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_row = _resolveComponent("van-row");
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_tabs, {
        active: active.value,
        "onUpdate:active": _cache[3] || (_cache[3] = $event => active.value = $event),
        animated: "",
        onClickTab: onClickTab
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tab, {
          title: "我放入的纸条",
          name: "2"
        }, {
          default: _withCtx(() => [_createVNode(_component_van_list, {
            loading: loading.value,
            "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
            finished: finished.value,
            "finished-text": "没有更多了"
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(myPaper), (item, index) => {
              return _openBlock(), _createBlock(_component_van_cell, {
                key: index
              }, {
                default: _withCtx(() => [_createVNode(_component_van_row, {
                  class: "left"
                }, {
                  default: _withCtx(() => [_createVNode(_component_van_col, {
                    span: "24"
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, " 联系方式: " + _toDisplayString(item.numberType === 1 ? 'QQ' : '微信') + " : " + _toDisplayString(item.number), 1)]),
                    _: 2
                  }, 1024), _createVNode(_component_van_col, {
                    span: "24"
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, " 交友宣言: " + _toDisplayString(item.declaration), 1)]),
                    _: 2
                  }, 1024), _createVNode(_component_van_col, {
                    span: "24"
                  }, {
                    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.pictureUrlList, (list, picIndex) => {
                      return _openBlock(), _createBlock(_component_van_image, {
                        class: "van-image",
                        width: "5rem",
                        height: "5rem",
                        src: list.path,
                        onClick: $event => showImage(picIndex, item.pictureUrlList)
                      }, null, 8, ["src", "onClick"]);
                    }), 256))]),
                    _: 2
                  }, 1024), _createVNode(_component_van_col, {
                    span: "24",
                    class: "right"
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_van_button, {
                      class: "destructionButton",
                      type: "warning",
                      size: "small",
                      onClick: $event => destructionPaper(item.paperId)
                    }, {
                      default: _withCtx(() => [_createTextVNode("销毁 ")]),
                      _: 2
                    }, 1032, ["onClick"])])]),
                    _: 2
                  }, 1024)]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024);
            }), 128))]),
            _: 1
          }, 8, ["loading", "finished"])]),
          _: 1
        }), _createVNode(_component_van_tab, {
          title: "我抽取的纸条",
          name: "1"
        }, {
          default: _withCtx(() => [_createVNode(_component_van_list, {
            loading: loading.value,
            "onUpdate:loading": _cache[2] || (_cache[2] = $event => loading.value = $event),
            finished: finished.value,
            "finished-text": "没有更多了"
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(myExtractPaper), (item, index) => {
              return _openBlock(), _createBlock(_component_van_cell, {
                key: index
              }, {
                default: _withCtx(() => [_createVNode(_component_van_row, {
                  class: "left"
                }, {
                  default: _withCtx(() => [_createVNode(_component_van_col, {
                    span: "20"
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_4, " 联系方式: " + _toDisplayString(item.numberType === 1 ? 'QQ' : '微信') + " : " + _toDisplayString(item.number), 1)]),
                    _: 2
                  }, 1024), _createVNode(_component_van_col, {
                    span: "24"
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_5, "交友宣言: " + _toDisplayString(item.declaration), 1)]),
                    _: 2
                  }, 1024), _createVNode(_component_van_col, {
                    span: "24"
                  }, {
                    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.pictureUrlList, (list, picIndex) => {
                      return _openBlock(), _createBlock(_component_van_image, {
                        class: "van-image",
                        width: "5rem",
                        height: "5rem",
                        src: list.path,
                        onClick: $event => showImage(picIndex, item.pictureUrlList)
                      }, null, 8, ["src", "onClick"]);
                    }), 256))]),
                    _: 2
                  }, 1024), _createVNode(_component_van_col, {
                    span: "24"
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createTextVNode(" 纸条有问题?截图联系 "), _createVNode(_component_van_button, {
                      type: "primary",
                      size: "mini",
                      onClick: _cache[1] || (_cache[1] = $event => kefu.value = true)
                    }, {
                      default: _withCtx(() => [_createTextVNode("客服")]),
                      _: 1
                    })])]),
                    _: 1
                  })]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024);
            }), 128))]),
            _: 1
          }, 8, ["loading", "finished"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["active"]), _createVNode(_component_van_action_sheet, {
        show: kefu.value,
        "onUpdate:show": _cache[4] || (_cache[4] = $event => kefu.value = $event),
        title: "扫码关注公众号加客服微信"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_van_image, {
          width: "15rem",
          height: "15rem",
          src: "/static/images/qrcode_gongzhonghao.jpg"
        })])]),
        _: 1
      }, 8, ["show"]), _createVNode(Footer)]);
    };
  }
};